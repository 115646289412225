<template>
  <base-layout>
    <PassAddEdit @add-edit="$store.dispatch('fsTable/fetchData')" />

    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitlePlus
            title="Pass"
            @plus="add"
            :hide-plus="$acl.canNotCreate('pass')"
          />

          <div class="flex md:justify-end">
            <DateRangePicker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>

        <div class="summary-card-container">
          <SummaryCard
            title="Total Pass Users"
            :value="indexMetaData.summary.total_pass_user"
            variant="purple"
            size="large"
          />

          <SummaryCard
            title="Earnings From Pass"
            :value="indexMetaData.summary.earning_from_pass"
            :symbol="getCurrencySymbol(indexMetaData.summary.currency)"
            variant="green"
            size="large"
          />
        </div>
      </div>
      <div
        v-if="$acl.canNotView('pass')"
        class="py-5 font-bold text-center text-gray-600"
      >
        You don't have permission to see data. Please Ask your admin to give you
        permission for this screen.
      </div>

      <FSTable
        v-else
        :fst-id="`passIndex`"
        :headers="tableHeaders"
        :endpoint="indexDataEndpoint"
        @meta="(e) => (indexMetaData = e)"
      >
        <!-- <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="`promotionIndex`"
              :slot-width="slotWidth"
              :options="filterOptions"
            />
          </keep-alive>
        </template> -->

        <template #default="{ data }">
          <FSTableRow
            v-for="(item, itemIndex) in data"
            :key="itemIndex"
            text-fallback-always
          >
            <FSTableRowItem
              :text="item.id"
              :truncate="-5"
              :to="{ name: 'PassDetails', params: { id: item.id } }"
            />

            <FSTableRowItem :text="getPassCategoryName(item.pass_type)" />

            <FSTableRowItem>
              <RouterLink
                v-if="item.assigned_fleet"
                class="text-blue-600 "
                :key="item.assigned_fleet.id"
                :to="{
                  name: 'ViewFleetProfile',
                  params: { id: item.assigned_fleet.id },
                }"
                target="_blank"
                >{{ item.assigned_fleet.name }}</RouterLink
              >
            </FSTableRowItem>

            <FSTableRowItem>
              {{ getFormattedDiscountRate(item.reservation_charge) }}
            </FSTableRowItem>

            <FSTableRowItem>
              {{ getFormattedDiscountRate(item.unlock_charge) }}
            </FSTableRowItem>

            <FSTableRowItem>
              {{ getFormattedDiscountRate(item.per_minute_charge) }}
            </FSTableRowItem>

            <FSTableRowItem>
              {{ formatPrice({ amount: item.amount, item }) }}
            </FSTableRowItem>

            <FSTableRowItem>
              <XStatus
                :text="item.status"
                :variant="getItemVariant(item.status)"
                profile="payment"
              />
            </FSTableRowItem>

            <FSTableRowItem>
              <div
                class="flex items-center gap-1"
                v-if="item.status !== 'Expired'"
              >
                <OtoEditIcon @click="edit(item)" />
                <MoreActions :data="item" />
              </div>
              <p v-else></p>
            </FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import SummaryCard from '@/components/cards/SummaryCard'
import MoreActions from './MoreActions.vue'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  // FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'

import { PassConfig } from '@/config/PassConfig'
import { getPassCategoryName } from '@/utils'

import { EventBus } from '@/utils/EventBus'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'

import PassAddEdit from '@/views/pass/PassAddEdit'
import XStatus from '@/components/badge/XStatus'

export default {
  name: 'PassManagement',
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,

    OtoEditIcon,
    DateRangePicker,
    PassAddEdit,
    XStatus,
    MoreActions,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    // FSTableFilter,
    SummaryCard,
  },
  computed: {
    currencySymbol() {
      return this?.$org?.default_currency?.symbol || '$'
    },
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          total_pass_user: 0,
          earning_from_pass: 0,
        },
      },
      indexDataEndpoint: PassConfig.api.index,
      tableHeaders: [
        { text: 'Pass ID', width: '10%', sort: 'id' },
        { text: 'Pass Type', width: '10%', sort: 'title' },
        { text: 'Fleet', width: '10%', sort: 'assigned_fleet' },
        {
          text: 'Reservation Charge',
          width: '13%',
          sort: 'promotion_category',
        },
        { text: 'Unlock Charge', width: '13%', sort: 'code' },
        { text: 'Per Minutes Charge', width: '13%', sort: 'end_date' },
        { text: 'Pass Amount', width: '13%', sort: 'max_amount' },
        { text: 'Status', width: '10%', sort: 'is_active' },
        { text: 'Actions', width: '5%', sort: null },
      ],
    }
  },
  methods: {
    onApplyFilterDateRange,
    getPassCategoryName,
    add: function() {
      EventBus.$emit(PassConfig.events.editingData, {
        // enable status-switch by default
        status_active: true,
      })
      dispatchEvent(new Event(PassConfig.events.sorToggle))
      // console.log("asdfdsaf");
    },
    edit: function(item) {
      if (this.$acl.canNotUpdate('pass')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'pass',
          })
        )
        return
      }
      const pass = { ...item, assigned_fleet: item.assigned_fleet?.id ?? null }

      EventBus.$emit(PassConfig.events.editingData, pass)
      dispatchEvent(new Event(PassConfig.events.sorToggle))
    },
    onOpenES() {
      console.log('onES')
    },
    getItemVariant(data) {
      if (data === 'Active') return 'green'
      if (data === 'Inactive') return 'gray'
      return 'red'
    },

    getCurrencySymbol(currency) {
      if (currency) {
        return currency.symbol
      }

      return this.currencySymbol
    },
    getPassCurrencySymbol(item) {
      const fleet = item.assigned_fleet

      if (fleet) {
        return fleet.country?.currency_symbol || this.currencySymbol
      }

      return this.currencySymbol
    },
    formatPrice({ amount, item } = {}) {
      if (!amount || typeof Number(amount) !== 'number') {
        return '--'
      }
      const c = this.getPassCurrencySymbol(item)
      return `${c} ${Number(amount).toFixed(2)}`
    },
    getFormattedDiscountRate(rate) {
      if (rate === null || typeof rate === 'undefined') {
        return '--'
      }

      return `${parseFloat(rate).toFixed(0)}%`
    },
  },
}
</script>

<style></style>
