<template>
  <slide-over-right :id="sorId" editType="pass" v-on:showModal="showModal">
    <loading :active.sync="isLoading"></loading>

    <div class="flex flex-wrap px-6 pt-12 w-full">
      <div class="items-center w-1/2 panel-title header-title">
        {{ titleText }}
      </div>
    </div>

    <div class="px-6">
      <pass-card
        :pass_type="form.pass_type"
        :price="form.price"
        :unlock_charge="show_unlock_charge"
        :per_minute_charge="1"
        :reservation_charge="1"
        :free_minutes_each_ride="show_free_minutes_each_ride"
        :free_minutes="form.free_minutes_each_ride"
      />
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(confirmSubmit)"
          enctype="multipart/form-data"
          class="sm:pb-24 2xl:px-6 2xl:pb-3 sm:px-1"
        >
          <div class="flex items-center pt-8">
            <!-- start: name & phone -->
            <div class="pr-2 w-1/2">
              <ValidationProvider
                name="Pass Type"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Pass Type *" />
                <t-rich-select
                  placeholder="Pass Type"
                  :options="PassTypes"
                  valueAttribute="id"
                  textAttribute="name"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.pass_type"
                  @change="changeType"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
            <div class="pl-2 w-1/2">
              <text-input
                rules="required"
                label="Pass Price"
                placeholder="$49.99"
                v-model="form.price"
              />
            </div>
          </div>

          <div class="flex items-center pt-2">
            <div class="pr-2 w-1/2">
              <ValidationProvider
                name="Profile Type"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Profile Type *" />
                <t-rich-select
                  placeholder="Profile Type"
                  :options="Profiles"
                  valueAttribute="id"
                  textAttribute="name"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.profile_type"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
            <div class="pl-2 w-1/2">
              <ValidationProvider
                name="Vehicle Type"
                rules="required"
                v-slot="{ errors }"
              >
                <input-label :error="errors[0]" text="Vehicle Type *" />
                <t-rich-select
                  placeholder="Vehicle Type"
                  :options="Vehicles"
                  valueAttribute="id"
                  textAttribute="name"
                  :hideSearchBox="true"
                  :variant="{ danger: !!errors[0] }"
                  v-model="form.vehicle_type"
                />
                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </div>
          </div>

          <div class="mt-2">
            <input-label text="Status" />
            <t-rich-select
              placeholder="Select status"
              :options="statusOptions"
              valueAttribute="value"
              textAttribute="text"
              :hideSearchBox="true"
              v-model="is_active"
              class="w-full"
            />
          </div>

          <div class="mt-2">
            <ValidationProvider name="Type" rules="" v-slot="{ errors }">
              <input-label :error="errors[0]" text="Assigned Fleet *" />
              <TRichSelect
                v-model="form.assigned_fleet"
                :options="getFleetOptions"
                :placeholder="`Select a fleet`"
              />
              <input-error-item :message="errors[0]" />
            </ValidationProvider>
          </div>

          <input-label text="Discount Unlock Charge Allowed" class="pt-4" />
          <div
            :class="
              `flex items-center pt-2 ${
                show_unlock_charge == 0 ? 'pb-6' : 'pb:0'
              }`
            "
          >
            <t-rich-select
              placeholder=""
              :options="unLockCharge"
              valueAttribute="value"
              textAttribute="text"
              :hideSearchBox="true"
              v-model="show_unlock_charge"
              @change="changeUnlockCharge"
              class="w-full"
            />
          </div>

          <div v-if="show_unlock_charge == 1" class="flex items-center pt-4">
            <text-input
              rules="required"
              label="Discount Unlock Charge (%)"
              placeholder="$3"
              v-model="form.unlock_charge"
            />
          </div>

          <input-label text="Discount Reservation Charge" class="pt-4" />
          <div
            :class="
              `flex items-center pt-2 ${
                show_reservation_charge == 0 ? 'pb-6' : 'pb:0'
              }`
            "
          >
            <t-rich-select
              placeholder=""
              :options="reservationCharge"
              valueAttribute="value"
              textAttribute="text"
              :hideSearchBox="true"
              v-model="show_reservation_charge"
              @change="changeReservationCharge"
              class="w-full"
            />
          </div>

          <div
            v-if="show_reservation_charge == 1"
            class="flex items-center pt-4"
          >
            <text-input
              rules="required"
              label="Discount Reservation Charge (%)"
              placeholder="$3"
              v-model="form.reservation_charge"
            />
          </div>

          <input-label text="Discount Per Minutes Charge" class="pt-4" />
          <div
            :class="
              `flex items-center pt-2 ${
                show_per_minute_charge == 0 ? 'pb-6' : 'pb:0'
              }`
            "
          >
            <t-rich-select
              placeholder=""
              :options="perCharges"
              valueAttribute="value"
              textAttribute="text"
              :hideSearchBox="true"
              v-model="show_per_minute_charge"
              @change="perMinuteCharge"
              class="w-full"
            />
          </div>

          <div
            v-if="show_per_minute_charge == 1"
            class="flex items-center pt-4"
          >
            <text-input
              rules="required"
              label="Discounted Per Minute Charge (%)"
              placeholder="15"
              v-model="form.per_minute_charge"
            />
          </div>

          <input-label text="Free Time Allowed" class="pt-4" />
          <div
            :class="
              `flex items-center pt-2 ${
                show_free_minutes_each_ride == 0 ? 'pb-6' : 'pb:0'
              }`
            "
          >
            <t-rich-select
              placeholder=""
              :options="perCharges"
              valueAttribute="value"
              textAttribute="text"
              :hideSearchBox="true"
              v-model="show_free_minutes_each_ride"
              @change="showFreeEachRide"
              class="w-full"
            />
          </div>

          <div
            v-if="show_free_minutes_each_ride == 1"
            class="flex items-center pt-4"
          >
            <text-input
              rules="required"
              label="Free Time Each Ride"
              placeholder="30 mins"
              v-model="form.free_minutes_each_ride"
            />
          </div>

          <div class="flex items-center pt-6">
            <div class="pr-2 w-1/2">
              <text-input
                rules=""
                label="Pass Start Date"
                v-model="start_date"
                type="date"
                v-on:input="onChangeStartDate"
              />
            </div>
            <div class="pl-2 w-1/2">
              <text-input
                rules=""
                label="Pass Expire Date"
                v-model="end_date"
                type="date"
                v-on:input="onChangeExpireDate"
              />
            </div>
          </div>

          <button type="submit" ref="submitButton" class="hidden">Save</button>
        </form>
      </ValidationObserver>
      <div class="flex justify-end items-center pr-2 mt-6">
        <slide-over-right-trigger :id="sorId">
          <anchor-button variant="secondary">Cancel</anchor-button>
        </slide-over-right-trigger>
        <t-button type="submit" @click="submit()" class="ml-3">Save</t-button>
      </div>
    </div>
  </slide-over-right>
</template>

<script>
import { PassConfig } from '@/config/PassConfig'
// import { FleetConfig } from '@/config/FleetConfig'
import { EventBus } from '@/utils/EventBus'
import SlideOverRight from '@/components/modals/SlideOverRight'
import TextInput from '@/components/form/TextInput'
import InputLabel from '@/components/form/InputLabel'
import AnchorButton from '@/components/form/AnchorButton'
import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
import xMan from '@/utils/xMan'
import PassCard from '../../components/cards/PassCard.vue'

export default {
  name: 'AddPass',
  components: {
    SlideOverRight,
    TextInput,
    InputLabel,
    AnchorButton,
    SlideOverRightTrigger,
    PassCard,
  },
  data() {
    return {
      sorId: PassConfig.events.sorId,
      isLoading: false,
      form: {},
      PassTypes: [
        {
          id: 'DD',
          name: 'Daily',
        },
        {
          id: 'WE',
          name: 'Weekly',
        },
        {
          id: 'MM',
          name: 'Monthly',
        },
        {
          id: 'YY',
          name: 'Yearly',
        },
      ],
      Vehicles: [
        {
          id: 'A',
          name: 'All',
        },
        {
          id: 'S',
          name: 'Bike',
        },
        {
          id: 'E',
          name: 'EBike',
        },
        {
          id: 'P',
          name: 'Scooter',
        },
      ],
      Profiles: [
        {
          id: 'A',
          name: 'All',
        },
        {
          id: 'S',
          name: 'Student',
        },
        {
          id: 'P',
          name: 'Professional',
        },
      ],
      unLockCharge: [
        {
          value: 0,
          text: 'No',
        },
        {
          value: 1,
          text: 'Yes',
        },
      ],
      reservationCharge: [
        {
          value: 0,
          text: 'No',
        },
        {
          value: 1,
          text: 'Yes',
        },
      ],
      statusOptions: [
        {
          value: false,
          text: 'Inactive',
        },
        {
          value: true,
          text: 'Active',
        },
      ],
      perCharges: [
        {
          value: 0,
          text: 'No',
        },
        {
          value: 1,
          text: 'Yes',
        },
      ],
      show_unlock_charge: 1,
      show_reservation_charge: 1,
      show_per_minute_charge: 1,
      show_free_minutes_each_ride: 1,
      fleets: [],
      cardTitle: '',
      is_active: false,
      start_date: '',
      end_date: '',
      item: {},
    }
  },
  async created() {
    this.fleets = await this.$http
      .get('/dashboard/fleets/?dropdown')
      .then((res) => res.data.data)
      .catch((err) => console.log('fleetListErr', err))
  },
  mounted() {
    EventBus.$on(PassConfig.events.editingData, (item) => {
      this.item = item
      this.form = {}

      if (Object.keys(this.item).length > 1) {
        //edit
        this.getPassDataByID(item.id)
      } else {
        //add
        this.init()
      }
    })
  },
  computed: {
    titleText() {
      return this.isEditing ? 'Edit Pass' : 'Add Pass'
    },
    isEditing: function() {
      // enabled status-switch by default
      return Object.keys(this.item).length > 1
    },
    actionButtonText: function() {
      return this.isEditing ? 'Update' : 'Save'
    },
    getFleetOptions() {
      return this.fleets.map((fleet) => ({
        text: `${fleet.name} (${fleet.country.code3})`,
        value: fleet.id,
      }))
    },
  },
  watch: {},
  methods: {
    //
    changeUnlockCharge(item) {
      this.show_unlock_charge = item
    },
    changeReservationCharge(item) {
      this.show_reservation_charge = item
    },
    perMinuteCharge(item) {
      this.show_per_minute_charge = item
    },
    showFreeEachRide(item) {
      this.show_free_minutes_each_ride = item
    },
    //
    init() {
      this.start_date = ''
      this.end_date = ''
      this.show_unlock_charge = 1
      this.show_reservation_charge = 1
      this.show_per_minute_charge = 1
      this.show_free_minutes_each_ride = 1
    },
    submit() {
      this.$refs.submitButton.click()
    },
    showModal() {
      dispatchEvent(new Event(PassConfig.events.sorToggle))
    },
    changeType(item) {
      this.form.pass_type = item
    },
    onChangeStartDate(event) {
      this.start_date = event
      this.getValidateDays()
    },
    onChangeExpireDate(event) {
      this.end_date = event
      this.getValidateDays()
    },
    getValidateDays() {
      if (this.start_date != '' && this.end_date != '') {
        let date1 = new Date(this.start_date)
        let date2 = new Date(this.end_date)

        var Difference_In_Time = date2.getTime() - date1.getTime()
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
        if (Difference_In_Days >= 0) {
          this.validate_day = Difference_In_Days + ' days'
        } else {
          this.start_date = ''
          this.end_date = ''
        }
      }
    },
    getPassDataByID(id) {
      this.$http
        .get(PassConfig.api.detail(id))
        .then((res) => {
          var result = res.data
          this.form = {
            price: result.amount,
            per_minute_charge: result.per_minute_charge,
            reservation_charge: result.reservation_charge,
            unlock_charge: result.unlock_charge,
            pass_type: result.pass_type,
            profile_type: result.profile_type,
            vehicle_type: result.vehicle_type,
            free_minutes_each_ride: result.free_minutes_each_ride,
            assigned_fleet: result.assigned_fleet?.id ?? null,
          }
          this.is_active = result.is_active
          ;(this.show_free_minutes_each_ride =
            result.free_minutes_each_ride &&
            result.free_minutes_each_ride != null
              ? 1
              : 0),
            (this.show_unlock_charge =
              result.unlock_charge && result.unlock_charge != null ? 1 : 0),
            (this.show_reservation_charge =
              result.reservation_charge && result.reservation_charge != null
                ? 1
                : 0),
            (this.show_per_minute_charge =
              result.per_minute_charge != '' && result.per_minute_charge != null
                ? 1
                : 0),
            (this.start_date = result.start_date
              ? result.start_date.split('/')[2] +
                '-' +
                result.start_date.split('/')[1] +
                '-' +
                result.start_date.split('/')[0]
              : '')
          this.end_date = result.end_date
            ? result.end_date.split('/')[2] +
              '-' +
              result.end_date.split('/')[1] +
              '-' +
              result.end_date.split('/')[0]
            : ''
        })
        .catch((err) => {
          console.log(err)
        })
    },

    async confirmSubmit() {
      const formProxy = { ...this.form }
      formProxy.is_active = this.is_active
      if (this.state_date != '' && this.end_date != '') {
        formProxy.start_date =
          this.start_date.split('-')[2] +
          '/' +
          this.start_date.split('-')[1] +
          '/' +
          this.start_date.split('-')[0]
        formProxy.end_date =
          this.end_date.split('-')[2] +
          '/' +
          this.end_date.split('-')[1] +
          '/' +
          this.end_date.split('-')[0]
      } else {
        formProxy.start_date = ''
        formProxy.end_date = ''
      }

      if (this.show_unlock_charge == 0) {
        formProxy.unlock_charge = ''
      }

      if (this.show_reservation_charge == 0) {
        formProxy.reservation_charge = ''
      }

      if (this.show_per_minute_charge == 0) {
        formProxy.per_minute_charge = ''
      }

      if (this.show_free_minutes_each_ride == 0) {
        formProxy.free_minutes_each_ride = ''
      }

      if (formProxy.assigned_fleet == null) {
        delete formProxy.assigned_fleet
      }

      console.log('form = ', formProxy)

      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? PassConfig.api.update(this.item.id)
        : PassConfig.api.create
      let data = new xMan(formProxy).toFormData()

      let message = this.isEditing
        ? 'Pass updated successfully'
        : 'Pass added successfully'

      this.isLoading = true
      try {
        let response = await this.$http({
          method,
          url,
          data,
        })

        console.log('response = ', url, '  ', method, '  ')
        console.log('data = ', response)

        this.isLoading = false
        // Close the slideover
        dispatchEvent(new Event(PassConfig.events.sorToggle))
        dispatchEvent(new Event(PassConfig.events.refresh))
        this.$emit('add-edit')
        // Reset the form
        this.form = {}
        this.$notify({
          group: 'bottomLeft',
          type: 'success',
          title: 'Success',
          text: message,
        })
        // Resolved
        return response.status
      } catch (error) {
        const e = error.response
        console.log('err', e)

        this.$notify({
          group: 'bottomLeft',
          type: 'error',
          title: `Error Occured [Code: ${e.status}]`,
          text: e.data.detail ?? 'The action could not be executed',
        })

        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.header-status {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
  margin-right: 10px;
}
.toggle-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.promo-type-card {
  width: 100%;
  height: 200px;
  background: white;
  border-radius: 22px;
  border: 3px dashed #ee9200;
  margin-top: 20px;
  position: relative;
  .promo-right-icon {
    width: 200px;
    height: 55px;
    background: #ee9200;
    position: absolute;
    right: -3px;
    top: -3px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 60px;
    display: flex;
    span {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      font-family: Roboto;
    }
  }
  .promo-card-body {
    h3 {
      font-family: Roboto;
      font-size: 37px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #0d0d0d;
    }
    span {
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ee9200;
      margin-top: 5px;
    }
  }
}
.multi-selecter {
  span {
    width: 100%;
  }
}
.multiselect__tags {
  border-color: #cbd5e0 !important;
}
</style>
